







































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import { TypedComponentProps } from "@/vue-props-types"
import TextField from "@/components/textFields/TextField.vue"
import { Props } from "./types"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import DateTime from "@/models/DateTime"
import { i18nContainer } from "@/containers/I18nContainer"
import { EmailAddress } from "node_modules/@microsoft/microsoft-graph-types/microsoft-graph"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import Loading from "@/components/Loading.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { appContainer } from "@/containers/AppContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { CandidateDate } from "@/models/CandidateDate"
import { MeetingContent } from "@/models/Meeting"

enum ViewType {
  Month = "month",
  Week = "week",
  Day = "day",
}

interface CalendarData {
  id: string
  name: string
  start: Date
  end: Date
  color: string
  timed: boolean
  attendees: Array<EmailAddress>
  hasOnlineMtg: boolean
  body: string
  contents: MeetingContent
}

interface State {
  events: Array<MicrosoftGraph.Event>
  gettedMonthEvents: Array<string>
  candidates: Array<CandidateDate>
  focus: string
  type: ViewType
}

const propsOptions = {}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      Loading,
      I18nFormattedMessage,
      TextField,
      WithBackPageLayout,
    },

    setup(_, { emit }) {
      const state = reactive<State>({
        events: [],
        gettedMonthEvents: [],
        candidates: [],
        focus: new DateTime().toDateString(),
        type: ViewType.Month,
      })

      const { state: appState } = appContainer.useContainer()
      const {
        getEventsAsync,
        getMeetingsAsync,
        getSukerakuAgendasAsync,
      } = meetingContainer.useContainer()
      const { formatMessage } = i18nContainer.useContainer()
      const { getContext } = teamsContextContainer.useContainer()
      const isMonthView = computed(() => state.type === ViewType.Month)
      const viewData = computed(() => {
        return state.events?.map(
          e =>
            ({
              id: e.id,
              name: e.subject,
              start: new DateTime(
                e.start?.dateTime,
                e.start?.timeZone || undefined
              )._value.toDate(),
              end: new DateTime(
                e.end?.dateTime,
                e.end?.timeZone || undefined
              )._value.toDate(),
              color: "",
              timed: !e.isAllDay,
              body: e.body?.content,
              hasOnlineMtg: e.isOnlineMeeting,
              attendees: e.attendees?.map(a => a.emailAddress) || [],
              contents: state.candidates.find(c => c.o365iCalUId === e.iCalUId)
                ?.contents,
            } as CalendarData)
        )
      })

      const diplayDayView = (date: string) => {
        state.focus = date
        state.type = ViewType.Day
      }

      const diplayType = (viewType: ViewType) => {
        switch (viewType) {
          case ViewType.Month:
            return "月"

          case ViewType.Week:
            return "週"

          case ViewType.Day:
            return "日"

          default:
            return ""
        }
      }

      const displyMonthView = () => {
        if (isMonthView.value) return
        state.type = ViewType.Month
      }

      const title = computed<string>(() => {
        if (isMonthView.value) {
          return new DateTime(state.focus).toMonthJpString()
        } else {
          return new DateTime(state.focus).toDateJpString()
        }
      })

      const formatEventTime = (dateTime: string) => {
        return new Date(dateTime).toLocaleTimeString("ja-JP", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      }

      const showEvent = ({ event }: { event: CalendarData }) => {
        const taraget = state.events.find(ev => ev.id === event.id)
        if (taraget) emit("clickEvent", taraget, event.contents)
      }

      const getDayLabelColor = (date: string): string => {
        const day = new Date(date)
        const today = new Date()

        const isToday =
          today.getFullYear() === day.getFullYear() &&
          today.getMonth() === day.getMonth() &&
          today.getDate() === day.getDate()

        if (isToday) {
          return "lightGray"
        } else {
          return "white"
        }
      }

      const getWeekdayColor = (
        weekday: number,
        date: string,
        viewType: string
      ) => {
        const isCurrentMonth =
          new Date(state.focus).getMonth() === new Date(date).getMonth()

        if (!isCurrentMonth && viewType === "monthView")
          return "grey--text text--lighten-2"
        if (weekday === 0) return "red--text"
        if (weekday === 6) return "blue--text text--darken-2"
      }

      const getKey = (target: DateTime) => `${target.toMonthJpString()}`

      const getBeforeAndAfterMonthEvents = async (target: string) => {
        let beforeMonth = new DateTime(target).add({ months: -1 })
        let afterMonth = new DateTime(target).add({ months: 1 })
        while (getKey(beforeMonth) !== getKey(afterMonth)) {
          if (!state.gettedMonthEvents.includes(getKey(beforeMonth))) break
          beforeMonth = beforeMonth.add({ months: 1 })
        }
        while (getKey(beforeMonth) !== getKey(afterMonth)) {
          if (!state.gettedMonthEvents.includes(getKey(afterMonth))) break
          afterMonth = afterMonth.add({ months: -1 })
        }
        if (
          getKey(beforeMonth) === getKey(afterMonth) &&
          state.gettedMonthEvents.includes(getKey(beforeMonth))
        )
          return
        const events = await getEventsAsync(
          beforeMonth.getStartOfMonth(),
          afterMonth.getEndOfMonth()
        )
        // Sukerakuの連携エラーで処理を止めたくないので、あえてawaitしていない
        if (appState.tenant?.value?.withSukeraku)
          getSukerakuAgendasAsync(
            beforeMonth.getStartOfMonth(),
            afterMonth.getEndOfMonth()
          ).then(candidates => {
            state.candidates.push(
              ...(candidates || []).filter(
                e => !state.candidates.some(m => m.id !== e.id)
              )
            )
          })
        const context = await getContext()
        const meetingStructures = await getMeetingsAsync(context.entityId)
        const meetingICaluids = meetingStructures.reduce((m, c) => {
          m.push(...c.meetings.map(m => m.iCaluid))
          return m
        }, [] as Array<string>)
        state.events.push(
          ...events.filter(e => !meetingICaluids.some(m => m === e.iCalUId))
        )
        while (beforeMonth.isSameOrBefore(afterMonth.getEndOfMonth())) {
          state.gettedMonthEvents.push(getKey(beforeMonth))
          beforeMonth = beforeMonth.add({ months: 1 })
        }
      }

      watch(
        () => state.focus,
        async newValue => {
          await getBeforeAndAfterMonthEvents(newValue)
        }
      )

      onMounted(async () => {
        await getBeforeAndAfterMonthEvents(state.focus)
      })

      return {
        state,
        viewData,
        i18nFormattedMessage: formatMessage,
        diplayDayView,
        title,
        showEvent,
        formatEventTime,
        ViewType,
        diplayType,
        displyMonthView,
        isMonthView,
        getDayLabelColor,
        getWeekdayColor,
      }
    },
  }
)
